import React from "react";
import ReactGA from 'react-ga';
import classNames from 'classnames';
import Link from 'next/link';
import Analytics from "../../../common_components/Common/Analytics";
export default function CommonParentSection(props) {
  const HandleViewClick = (e) => {
    // e.preventDefault();
    const Analyticsprops = {
      GA: true,
      title :`${props.title} View All`, 
      category: 'Home page-Events',
      action: `${props.title} View All `,
      label: `${props.title} View All clicked!`,
    }
    Analytics(Analyticsprops)
  }
  return (
    <section className="mt-5 common-parent">
      <div className="row">
        <div
          className={classNames(
            {
              "col-9 col-sm-4 col-md-3 col-lg-2": props.title === "Latest News",
            },
            {
              "col-9 col-sm-4  col-md-3 col-lg-3":
                props.title !== "Latest News",
            }
          )}
        >
          <h2 className="title">{props.title}</h2>
        </div>
        <div
          className={classNames(
            "px-0 pe-4 d-sm-block d-none",
            {
              "col-lg-9 col-sm-4 col-md-6": props.title === "Latest News",
            },
            {
              "col-lg-8 col-sm-4 col-md-6": props.title !== "Latest News" && props.title !== "BU4 Auto",
            },
            {
              "col-lg-7 col-sm-4 col-md-6": props.title === "BU4 Auto",
            }
          )}
        >
          <hr className="mt-3 ms-0" />
        </div>
        <div className={classNames(
          "col-3 col-sm-4 col-md-3",
          {
           "col-lg-2" :props.title === "BU4 Auto", 
          },
          {
            "col-lg-1" :props.title !== "BU4 Auto", 
           }
          )}
        >
          <div className="title text-end">
            {props.type === "upcoming" ? (
              <Link href="/upcoming" legacyBehavior>
                <a>
                  <span className="home-view-btn">View All</span>
                </a>
              </Link>
            ) : props.type === "brands" ? (
              <Link href="/electric-vehicle-companies" legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            ) : props.type === "news" ? (
              <Link href="/news" legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            ) : props.type === "blog" ? (
              <Link href="/blog" legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            ) : props.type === "sponsor" ? (
              <Link href="/electric-vehicle-companies/bu4" legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View Brand</span>
                </a>
              </Link>
            ) : props.type === "latest" ? (
              <Link href={"/" + (props?.subType === 1 ? 'electric-scooters?' : props?.subType === 2 ? 'electric-bikes?' : 'electric-cars?')  + "type=latest" } legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            ) :
            props.type === "vehicleCompare" ? (
              <Link href={"/compare" } legacyBehavior>
                <a>
                  <span className="home-view-btn" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            ) : (
              <Link href={"/electric-vehicles?type=" + props.type} legacyBehavior>
                <a>
                  <span className="home-view-btn text-right" onClick={(e) => HandleViewClick(e)}>View All</span>
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
      {props.children}
    </section>
  );
}
