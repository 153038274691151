import { GET_ALL_HOME_UPCOMING_LIST, GET_ALL_UPCOMING_LIST,TEST } from './types';
import axios from 'axios';
import { Get_Home_Upcoming_Vehicles_list, Get_Upcoming_Vehicles_list } from '../api';

export const getAllUpComingList = (paginationObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Upcoming_Vehicles_list, {
      params: paginationObj,
    });
    dispatch({
      type: GET_ALL_UPCOMING_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const getAllHomeUpComingList = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Home_Upcoming_Vehicles_list);
    dispatch({
      type: GET_ALL_HOME_UPCOMING_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (err) {
    return err;
  }
};
