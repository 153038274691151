import React, { useState } from "react";
import Image from "next/image"; 
import defaultlogo from '../../public/images/default_logo.png'
import { RiWhatsappFill } from "react-icons/ri";
import contactUsIcon from "../../public/images/used-images/call-us.svg"
import { isMobile } from "react-device-detect";
import Analytics from "./Analytics";

const FloatingActionButton = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const HandleEvents = (type) => {
    const Analyticsprops = {
      GA: true,
      MP: true,
      title: `${type}`,
      category: `Floating Button Events`,
      action: `${type}`,
      label: `${type} clicked!`,
      value: 1
    };
    Analytics(Analyticsprops);
  };

  const size= isMobile ? 35 : 40;

  return (
    <div>

      <div className="fab-container">
        <button className="fab" onClick={toggleModal}>
          <Image
            src={defaultlogo}
            alt="logo"
            width="60px"
            height="60px"
            className="rounded-circle"
            style={{ objectFit: "cover" }}
          />
        </button>
      </div>


      {isModalOpen && (
        <div className="fab-modal">
          <a href='tel:+91 9099900563' target="_blank" rel="noreferrer" className="fab-icon" onClick={() => HandleEvents('floating button call')}>
            <Image src={contactUsIcon} width={size} height={size} />
          </a>
          <a 
          href={`https://api.whatsapp.com/send/?phone=9099900348&text=Hii%2C+I+want+to+know+more+about+Electric+Vehicles&type=phone_number&app_absent=0`} 
          target='_blank' rel='noreferrer' className="fab-icon" onClick={() => HandleEvents('floating button WhatsApp')}>
            <svg id="_066" data-name="066" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
              <path id="Path_5470" data-name="Path 5470" d="M24,48A24,24,0,1,0,0,24,24,24,0,0,0,24,48Z" fill="#25d366" />
              <path id="Path_5471" data-name="Path 5471" d="M24.791,37.353h-.006a14.259,14.259,0,0,1-6.82-1.737L10.4,37.6l2.025-7.4a14.271,14.271,0,1,1,12.366,7.148Zm-6.475-4.321.433.257a11.844,11.844,0,0,0,6.037,1.653h0a11.863,11.863,0,1,0-10.048-5.559l.282.449-1.2,4.377Zm13.176-6.878a1.153,1.153,0,0,1,.488.321,2.982,2.982,0,0,1-.208,1.7,3.673,3.673,0,0,1-2.407,1.7,4.888,4.888,0,0,1-2.246-.141,20.456,20.456,0,0,1-2.033-.751,15.707,15.707,0,0,1-6.029-5.3L19,23.59l0,0A6.928,6.928,0,0,1,17.539,19.9,3.949,3.949,0,0,1,18.718,17l.07-.075a1.31,1.31,0,0,1,.951-.446c.238,0,.476,0,.683.013h.08c.208,0,.467,0,.722.611.1.236.242.586.394.956.307.747.646,1.572.706,1.692a.657.657,0,0,1,.03.625l-.05.1a2.025,2.025,0,0,1-.306.493c-.06.07-.121.145-.183.22-.123.149-.245.3-.352.405-.179.178-.364.371-.156.728a10.749,10.749,0,0,0,1.984,2.47,9.212,9.212,0,0,0,2.632,1.664c.1.042.177.077.236.106.356.179.564.149.772-.089s.891-1.041,1.129-1.4.476-.3.8-.178,2.08.982,2.437,1.16Z" fill="#fdfdfd" fill-rule="evenodd" />
            </svg>
          </a> 
        </div>
      )}
    </div>
  );
};

export default FloatingActionButton;