import Head from "next/head";
import Script from "next/script";

export default function Schema({ data, id }) {

    return (
        <Head>
            <script
                type="application/ld+json"
                id={id}
                dangerouslySetInnerHTML={{
                    __html: data
                }}
            />
            </Head>
    )

}