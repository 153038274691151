import LandingPage from '../components/LandingPage/index';
import { wrapper } from '../store/store';
import axios from 'axios';
import {
  Get_Popular_Vehicle,
  Get_Latest_Vehicle,
  Get_Home_Upcoming_Vehicles_list,
  getTopTenCompanies,
  Get_Random_News,
  Get_Upcoming_Vehicles_list,
  Get_News,
  get_vehicle_range,
  Get_Popular_Vehicle_Compare,
  Get_Latest_Electric_Scooter,
  Get_Latest_Electric_Bike,
  Get_Latest_Electric_Car,
  Get_Latest_Commercials,
  Get_Faq_List,
  Get_Latest_reviews,
  Get_Latest_Blog,
  Get_Vehicles_By_Company,
  Get_HomePage_Banner_Image,
  Get_Blog
} from '../api';
import setAuthToken from '../helpers/setAuthToken';
import Metatag from '../components/Meta/Metatag';
import Schema from '../components/Schema/Index';
import { useEffect, useState } from 'react';

// axios.defaults.headers["apikey"] = process.env.NEXT_PUBLIC_API_ACCESS_KEY;

export default function Home({ metaDetails, SchemaData }) {
  const [schemadataObj, setSchemaDataObj] = useState([])
  
  useEffect(()=>{
    const obj = JSON.parse(SchemaData);
    setSchemaDataObj(obj)
  },[SchemaData])
  return (
    <>
      <Metatag metaDetails={metaDetails} />
      {
        schemadataObj.length > 0 && schemadataObj.map((data, index)=> {
          const str = JSON.stringify(data);
          return(
              <Schema data={str} id={"homepage"} key={index}/>
          )
        })
      }
      <LandingPage />
    </>
  );
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async ({ req, res }) => {

    res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    )
    
    /* Popular Brand*/

    const PopularBrandRes = await axios.get(getTopTenCompanies);
    const PopularBrandArr = PopularBrandRes.data.result;
    store.dispatch({ type: 'GET_TOP_TEN_COMPANIES', payload: PopularBrandArr });

    /* Popular Vehicle */

    const PupularVehicleRes = await axios.get(Get_Popular_Vehicle);
    const PupularVehicleArr = PupularVehicleRes.data.result;
    store.dispatch({
      type: 'GET_POPULAR_VEHICLES',
      payload: PupularVehicleArr,
    });

    /* Latest Vehicle */

    const LatestVehicleRes = await axios.get(Get_Latest_Vehicle);
    const LatestVehicleArr = LatestVehicleRes.data.result;
    store.dispatch({ type: 'GET_LATEST_VEHICLES', payload: LatestVehicleArr });

    /* Upcoming Vehicle */


    const UpcomingVehicleRes = await axios.get(Get_Upcoming_Vehicles_list, {
      params: { page: 1, limit: 8 },
    });
    const UpcomingVehicleArr = UpcomingVehicleRes.data.result;
    store.dispatch({
      type: 'GET_ALL_UPCOMING_LIST',
      payload: UpcomingVehicleArr,
    });
    const HomeUpcomingVehicleRes = await axios.get(
      Get_Home_Upcoming_Vehicles_list
    );

    const HomeUpcomingVehicleArr = HomeUpcomingVehicleRes.data.result;
    store.dispatch({
      // type: 'TEST',
      type: 'GET_ALL_HOME_UPCOMING_LIST',
      payload: HomeUpcomingVehicleArr,
    });

    /* Home Page news */

    setAuthToken(
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE'
    );
    const HomePageNewsRes = await axios.get(Get_News, {
      params: { page: 1, limit: 8 },
    });
    const HomepageNewArr = HomePageNewsRes.data.result;
    store.dispatch({ type: 'GET_HOMEPAGE_NEWS', payload: HomepageNewArr });

    /* Random News*/

    setAuthToken(
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE'
    );
    const RandomNews = await axios.get(Get_Random_News, {
      params: { order_by: 1, limit: 10 },
    });

    const ResNews = RandomNews.data.result;
    store.dispatch({ type: 'GET_RANDOM_NEWS', payload: ResNews });

    /*get vehicle range*/

    const getvehicleRange = await axios.get(get_vehicle_range);
    const vehicleRange = getvehicleRange.data.result;
    store.dispatch({ type: 'GET_VEHICLE_RANGE', payload: vehicleRange });

    /*popular vehicles compare*/

    const vehiclecompareres = await axios.get(Get_Popular_Vehicle_Compare);
    const comparedata = vehiclecompareres.data.result;
    store.dispatch({
      type: 'GET_COMPARE_VEHICLE',
      payload: comparedata,
    });

    
    /* Latest Electric Scooter */

    const latestElectricScooter = await axios.get(Get_Latest_Electric_Scooter);
    const latestElectricScooterArr = latestElectricScooter.data.result;
    store.dispatch({
      type: 'GET_LATEST_ELECTRIC_SCOOTER',
      payload: latestElectricScooterArr,
    });

    /* Latest Electric Bike */

    const latestElectricBike = await axios.get(Get_Latest_Electric_Bike);
    const latestElectricBikeArr = latestElectricBike.data.result;
    store.dispatch({
      type: 'GET_LATEST_ELECTRIC_BIKE',
      payload: latestElectricBikeArr,
    });

    /* Latest Electric Car */

    // const latestElectricCar = await axios.get(Get_Latest_Electric_Car);
    // const latestElectricCarArr = latestElectricCar.data.result;
    // store.dispatch({
    //   type: 'GET_LATEST_ELECTRIC_CAR',
    //   payload: latestElectricCarArr,
    // });

    /* Latest Commercial vehicles */

    // const commercialVehicles = await axios.get(Get_Latest_Commercials);
    // const commercialVehiclesArr = commercialVehicles.data.result;
    // store.dispatch({
    //   type: 'GET_COMMERCIAL_VEHICLES',
    //   payload: commercialVehiclesArr,
    // });

    /* List Faq Web */

    const GetFAQ = await axios.get(Get_Faq_List);
    const GetFAQArr = GetFAQ.data.result;
    store.dispatch({
      type: 'GET_FAQ_LIST',
      payload: GetFAQArr,
    });

    /* Latest Reviews */

    // const LatestReviws = await axios.get(Get_Latest_reviews);
    // const LatestReviwsArr = LatestReviws.data.result;
    // store.dispatch({
    //   type: 'GET_LATEST_REVIEWS',
    //   payload: LatestReviwsArr,
    // });

    // Latest Blog

    // const BlogDataRes = await axios.get(Get_Latest_Blog);
    const BlogDataRes = await axios.get(Get_Blog);
    const BlogDataArr = BlogDataRes.data.result;
    store.dispatch({
      type: 'GET_BLOG_LIST',
      payload: BlogDataArr,
    });

    // Sponser product

    // const getBU4products = await axios.get(Get_Vehicles_By_Company + '?company=62d273290385927f49c31c15');
    // const bu4products = getBU4products.data.result;
    // store.dispatch({ type: 'BU4_PRODUCTS', payload: bu4products });

    //banner image

    const BannerimgRes = await axios.get(Get_HomePage_Banner_Image);
    const Bannerimgarr = BannerimgRes.data.result;
    store.dispatch({
      type: 'GET_HOMEPAGE_BANNER_IMAGE',
      payload: Bannerimgarr,
    });

    const metaDetails = [
      {
        pagetitle:
          'Electric Vehicle Marketplace in India | EVINDIA',
        title:
          'Electric Vehicle Marketplace in India | EVINDIA',
        description:
          'Explore the expansive Electric Vehicle marketplace at EVINDIA, where we provide comprehensive information on electric vehicles to help you make informed choices',
        image: '/logo-1.png',
        url: `${process.env.NEXT_PUBLIC_APP_BASE_URL}`,
        keywords: 'Electric Vehicles in India,Electric Scooters in India,India Electric Vehicle,Latest Electric Scooters'
      },
    ];

    const SchemaData = `[
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "EVINDIA",
          "url": "https://evindia.online/"
        },
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "EVINDIA",
          "url": "https://evindia.online/",
          "logo": {
            "@type": "ImageObject",
            "url": "https://evindia.online/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-b.93f6bbc4.png&w=828&q=75",
            "width": 240,
            "height": 60
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "telephone": "[+91 9099900563]",
            "email": "info@evindia.online"
          },
          "sameAs": [
            "https://www.facebook.com/evindia.online/",
            "https://www.instagram.com/evindia.online/",
            "https://www.youtube.com/channel/UCywB8N5ChjnfTNrvS4q8ZIA",
            "https://twitter.com/EVIndiaonline"
          ]
        }
      ]`

    return {
      props: {
        metaDetails,
        SchemaData
      },
    };
  }
);
